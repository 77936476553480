import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loadWorkingHours,
  saveWorkingHours,
  getBranchWorkingHours,
} from '@vezeeta/enterprise-store/lib/actions/workingHours';
import {
  getRoomDetails,
  setRoomScheduleTypes,
  setRoomReservationWindow,
  getRooms,
} from '@vezeeta/enterprise-store/lib/actions/room';
import { usePrevious } from '@vezeeta/web-utils';
import { status } from '@vezeeta/enterprise-utils/lib/enum';
import { loadSchedules } from '@vezeeta/enterprise-store/lib/actions/schedules';
import {
  SnackBarNew,
  Spinner,
  NewButton,
  NotificationBar,
  Field,
  EmptyState,
} from '@vezeeta/web-components';
import { showSnackBar, hideSnackBar } from '@vezeeta/enterprise-store/lib/actions/widgets';
import { USER_PRIVILEGES, getRoleBasedAccess } from 'app/RoleBasedAccess/RoleBasedAccess';
import DayWorkingHours from './DayShifts/DayShifts';
import {
  getReservationWindowOptions,
  getWorkingHoursTypes,
  weekDays,
  APPOITMENTS_TYPES,
  checkValidInterval,
  getStaticFieldValue,
} from './ManageDoctorWorkingHours.helper';
import {
  Container,
  SeparatorContainer,
  SeparatorLine,
  ButtonContainer,
  savebtnExtendedStyle,
  ExtendBarContainer,
  DoctorInfoContainer,
  DoctorInfoWrapper,
  DoctorImage,
  DoctorName,
  DoctorSpecialty,
  DoctorImageContainer,
  BookingTypeOptionsContainer,
} from './ManageDoctorWorkingHours.styles';
import { ExtendDropDownListItem, ExtendDropDownStyle } from './DayShifts/DayShifts.style';
import { translation } from './localization/translation';

const ManageDoctorWorkingHours = ({
  language,
  branchKey,
  clinicsLoaded,
  clinics,
  roomScheduleTypes,
  roomReservationWindow,
  branches,
  ramadanSchedule,
  workingHours,
  workingHoursLoadState,
  doctorInfo,
  saveLoadState,
  getRoomsResponse,
  branchWorkingHours,
  saveWorkingHoursLoadState,
  saveWorkingHoursResponse,
  getWorkingHoursLoadState,
  snackbarSettings,
  isRamadanWH,
  ...props
}) => {
  const roledBasedAccessCatalogue = getRoleBasedAccess();
  const canReadOnlyWH =
    roledBasedAccessCatalogue.workingHours_view.canReadOnly === USER_PRIVILEGES.AUTHORIZED;

  const localization = useMemo(() => translation[language], [language]);
  const reservationWindowOptions = useMemo(() => getReservationWindowOptions(localization), [
    localization,
  ]);
  const [branchWorkingHoursDetails, setBranchWorkingHoursDetails] = useState([]);
  const [workingHoursTypes, setWorkingHoursTypes] = useState([]);
  const [doctorWorkingHoursInfo, setDoctorWorkingHoursInfo] = useState({
    workingHours: [],
    reservationWindow: undefined,
    workingHoursType: undefined,
  });
  const [isBadResponse, toggleBadResponseNotification] = useState(false);
  const [badResponseMsg, setBadResponseMsg] = useState('');
  const [physicalRoomWHConflictList, setPhysicalRoomWHConflictList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const resetFormAndClose = () => {
    toggleBadResponseNotification(false);
    setPhysicalRoomWHConflictList([]);
    setDoctorWorkingHoursInfo({
      workingHours: [],
      reservationWindow: undefined,
      workingHoursType: undefined,
    });
  };

  useEffect(() => {
    if (isRamadanWH) {
      // GetRamadancSchedule
      props.loadSchedules();
    }
  }, []);

  useEffect(() => {
    if (branchKey) {
      props.getRooms({ branchKey });
      props.getBranchWorkingHours({ branchKey });
    }
  }, [branchKey]);

  const previousAccount = usePrevious(doctorInfo.AccountKey);
  useEffect(() => {
    if (doctorInfo && doctorInfo.AccountKey && previousAccount !== doctorInfo.AccountKey) {
      resetFormAndClose();
      const currentBranch = branches.find(branch => branchKey === branch.BranchKey);
      setWorkingHoursTypes(getWorkingHoursTypes(currentBranch.BookingTypeId, localization));
      setLoading(true);
      // GetAllowReservationWindow && GetScheduleType
      props.getRoomDetails(clinics.Clinics, true);
    }
  }, [doctorInfo && doctorInfo.AccountKey]);

  useEffect(() => {
    // GetWorkingHours
    if (doctorInfo && doctorInfo.AccountKey) {
      props.loadWorkingHours([doctorInfo.AccountKey], [doctorInfo.RoomKey], {
        scheduleKey:
          isRamadanWH && window.enableRamadan && ramadanSchedule.ScheduleKey
            ? ramadanSchedule.ScheduleKey
            : null,
      });
    }
  }, [doctorInfo.AccountKey]);

  useEffect(() => {
    if (roomScheduleTypes && roomReservationWindow) {
      const reservationWindow = roomReservationWindow.find(
        reservationAllowObj => reservationAllowObj.RoomKey === doctorInfo.RoomKey,
      );
      const workingHoursType = roomScheduleTypes.find(
        typeObj =>
          typeObj.AccountKey === doctorInfo.AccountKey && typeObj.RoomKey === doctorInfo.RoomKey,
      );
      setDoctorWorkingHoursInfo({
        ...doctorWorkingHoursInfo,
        reservationWindow: reservationWindow && reservationWindow.ReservationWindow,
        workingHoursType: workingHoursType && workingHoursType.ScheduleType,
      });
    }
  }, [roomScheduleTypes, roomReservationWindow]);

  useEffect(() => {
    if (workingHours && workingHours.length && getWorkingHoursLoadState === status.SUCCESS) {
      const list = workingHours.map((item, index) => ({
        DayOfWeek: weekDays[index],
        DayShifts: item.DayShifts,
        IsOpened: item.DayShifts.length > 0,
        DayOfWeekInt: item.DayOfWeekInt,
        isOverlap: false,
      }));
      setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: list });
      setLoading(false);
    }
  }, [getWorkingHoursLoadState]);

  useEffect(() => {
    if (
      branchWorkingHours &&
      branchWorkingHours.ReturnObject &&
      branchWorkingHours.ReturnObject.BranchWorkingHourModels
    ) {
      const branchWorkingHoursDetailsList = [];
      weekDays.forEach(dayOfWeek => {
        const dayObj = {
          DayOfWeek: dayOfWeek,
          isOpened: true,
          branchStartWorkingHour: undefined,
          branchEndWorkingHour: undefined,
        };
        const { BranchWorkingHourModels } = branchWorkingHours.ReturnObject;
        const branchWorkingHoursInCurrentDay = BranchWorkingHourModels.find(
          day => day.DayOfWeek === dayOfWeek,
        );
        if (branchWorkingHoursInCurrentDay) {
          dayObj.isOpened =
            branchWorkingHoursInCurrentDay && branchWorkingHoursInCurrentDay.IsOpened;
          dayObj.branchStartWorkingHour =
            branchWorkingHoursInCurrentDay && branchWorkingHoursInCurrentDay.StartTime;
          dayObj.branchEndWorkingHour =
            branchWorkingHoursInCurrentDay && branchWorkingHoursInCurrentDay.EndTime;
        }
        branchWorkingHoursDetailsList.push(dayObj);
      });
      setBranchWorkingHoursDetails(branchWorkingHoursDetailsList);
    }
  }, [branchWorkingHours]);

  const CheckTimeInSideInterval = (shiftBorder, shiftStart, shiftEnd) => {
    const shiftBorderNumerical = Number(shiftBorder.split(':').join(''));
    const shiftStartNumerical = Number(shiftStart.split(':').join(''));
    const shiftEndNumerical = Number(shiftEnd.split(':').join(''));
    return shiftBorderNumerical > shiftStartNumerical && shiftBorderNumerical < shiftEndNumerical;
  };

  const isValid = validateSaveBtn => {
    let isValidData = true;
    const temp = [...doctorWorkingHoursInfo.workingHours];
    temp.forEach((day, index) => {
      let shiftsHasOverLap = false;
      day.DayShifts.forEach((shiftX, indexX) => {
        const { StartTime, EndTime, SlotDuration, SlotsPerShift } = shiftX;
        // Check Missing Data
        if (
          StartTime === undefined ||
          EndTime === undefined ||
          SlotDuration === undefined ||
          SlotsPerShift === undefined
        ) {
          isValidData = false;
        }
        // Check Start < end time
        if (!checkValidInterval(StartTime, EndTime)) {
          isValidData = false;
        }
        // Check OverLap
        if (day.DayShifts.length > 1) {
          day.DayShifts.find((shiftY, indexY) => {
            if (indexX !== indexY) {
              if (
                CheckTimeInSideInterval(StartTime, shiftY.StartTime, shiftY.EndTime) ||
                CheckTimeInSideInterval(EndTime, shiftY.StartTime, shiftY.EndTime) ||
                (StartTime === shiftY.StartTime && EndTime === shiftY.EndTime)
              ) {
                shiftsHasOverLap = true;
                isValidData = false;
                return true;
              }
            }
            return false;
          });
        } else {
          shiftsHasOverLap = false;
        }
      });
      temp[index].isOverlap = shiftsHasOverLap;
    });
    if (!validateSaveBtn)
      setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
    return isValidData;
  };

  const onSave = () => {
    if (isValid()) {
      toggleBadResponseNotification(false);
      setPhysicalRoomWHConflictList([]);
      props.setRoomScheduleTypes([
        {
          ...doctorInfo,
          ScheduleType: doctorWorkingHoursInfo.workingHoursType,
        },
      ]);

      props.setRoomReservationWindow([
        {
          ...doctorInfo,
          ReservationWindow: doctorWorkingHoursInfo.reservationWindow,
        },
      ]);

      const weekList = [];
      doctorWorkingHoursInfo.workingHours.forEach(day => {
        if (day.IsOpened) {
          const dayShifts = [];
          day.DayShifts.forEach(shift => {
            if (doctorWorkingHoursInfo.workingHoursType === APPOITMENTS_TYPES.FIFO) {
              dayShifts.push({
                ...shift,
                SlotDuration: null,
              });
            } else {
              dayShifts.push({
                ...shift,
                SlotsPerShift: null,
              });
            }
          });

          weekList.push({
            DayOfWeek: day.DayOfWeek,
            DayShifts: dayShifts,
          });
        }
      });

      props.saveWorkingHours(
        {
          ActionMaker: 'Doctor',
          WeekDetails: weekList,
          BranchKey: branchKey,
        },
        [
          {
            ...doctorInfo,
            AllowReservationWindow: doctorWorkingHoursInfo.reservationWindow,
          },
        ],
        [
          {
            ...doctorInfo,
            ScheduleType: doctorWorkingHoursInfo.workingHoursType,
          },
        ],
        isRamadanWH && window.enableRamadan && ramadanSchedule.ScheduleKey
          ? ramadanSchedule.ScheduleKey
          : null,
      );
    }
  };

  const onChangeWorkingHoursType = val => {
    const temp = doctorWorkingHoursInfo.workingHours;
    for (let i = 0; i < temp.length; i += 1) {
      for (let j = 0; j < temp[i].DayShifts.length; j += 1) {
        if (temp[i].DayShifts[j].SlotDuration % 5 !== 0) {
          temp[i].DayShifts[j].StartTime = undefined;
          temp[i].DayShifts[j].EndTime = undefined;
          temp[i].DayShifts[j].SlotDuration = Math.ceil(val / 10) * 10;
        }
        temp[i].DayShifts[j].ShiftType = val;
      }
    }

    setDoctorWorkingHoursInfo({
      ...doctorWorkingHoursInfo,
      workingHours: temp,
      workingHoursType: val,
    });
  };

  const setOverLap = (index, isOverlapped) => {
    const workingHoursList = [...doctorWorkingHoursInfo.workingHours];
    if (workingHoursList && workingHoursList.length) {
      workingHoursList[index].isOverlap = isOverlapped;
      setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: workingHoursList });
    }
  };

  const onChangeReservationWindow = val => {
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, reservationWindow: val });
  };

  const addNewShift = dayIndex => {
    const temp = [...doctorWorkingHoursInfo.workingHours];
    temp[dayIndex].DayShifts.push({
      StartTime: '18:00:00',
      EndTime: '21:00:00',
      SlotDuration: 30,
      SlotsPerShift: 6,
      ErrorType: 0,
      ...doctorInfo,
      ShiftType: doctorWorkingHoursInfo.workingHoursType,
      ScheduleId:
        isRamadanWH && window.enableRamadan && ramadanSchedule.ScheduleKey
          ? ramadanSchedule.ScheduleKey
          : null,
    });
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
  };

  const onChangeSelection = dayIndex => {
    const temp = [...doctorWorkingHoursInfo.workingHours];
    temp[dayIndex].IsOpened = !temp[dayIndex].IsOpened;
    if (!(temp[dayIndex].DayShifts && temp[dayIndex].DayShifts.length)) {
      addNewShift(dayIndex);
    }
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
  };

  const onChangeTime = (dayIndex, shiftIndex, timeSlotAttribute, value) => {
    const temp = [...doctorWorkingHoursInfo.workingHours];
    temp[dayIndex].DayShifts[shiftIndex][timeSlotAttribute] = value;
    if (doctorWorkingHoursInfo.workingHoursType === APPOITMENTS_TYPES.FIFO) {
      temp[dayIndex].DayShifts[shiftIndex].SlotsPerShift = undefined;
    } else {
      // temp[dayIndex].DayShifts[shiftIndex].SlotDuration = undefined;
    }
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
  };

  const onChangeDuration = (dayIndex, shiftIndex, value) => {
    const temp = [...doctorWorkingHoursInfo.workingHours];
    if (doctorWorkingHoursInfo.workingHoursType === APPOITMENTS_TYPES.FIFO) {
      temp[dayIndex].DayShifts[shiftIndex].SlotsPerShift = value;
    } else {
      temp[dayIndex].DayShifts[shiftIndex].SlotDuration = value;
    }
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
  };

  const removeShift = (dayIndex, shiftIndex) => {
    const temp = [...doctorWorkingHoursInfo.workingHours];
    const shifts = temp[dayIndex].DayShifts.filter((shift, index) => index !== shiftIndex);
    temp[dayIndex].IsOpened = shifts.length > 0;
    temp[dayIndex].DayShifts = shifts;
    setDoctorWorkingHoursInfo({ ...doctorWorkingHoursInfo, workingHours: temp });
  };

  const prevSaveWorkingHoursLoadState = usePrevious(saveWorkingHoursLoadState);
  useEffect(() => {
    if (
      prevSaveWorkingHoursLoadState === status.SUBMITING &&
      saveWorkingHoursLoadState === status.SUCCESS
    ) {
      props.showSnackBar(
        localization.workingHoursSavedSuccessfully,
        '#ffff',
        '#31A636',
        'success2',
      );
    }

    if (saveWorkingHoursLoadState === status.FAIL) {
      setLoading(false);
      if (
        saveWorkingHoursResponse &&
        saveWorkingHoursResponse.data &&
        saveWorkingHoursResponse.data.Errors &&
        saveWorkingHoursResponse.data.Errors[0] &&
        saveWorkingHoursResponse.data.Errors[0].Property
      ) {
        if (saveWorkingHoursResponse.data.Errors[0].Property === 'PhysicalRoomConflict') {
          const physicalRoomConflict = saveWorkingHoursResponse.response.Message.split(',');
          setPhysicalRoomWHConflictList(physicalRoomConflict);
        } else if (saveWorkingHoursResponse.data.Errors[0].Property === 'BranchWorkingConflict') {
          setBadResponseMsg(localization.conflictMessage);
          toggleBadResponseNotification(true);
          setPhysicalRoomWHConflictList([]);
        } else {
          setBadResponseMsg(localization.someThingWentWrong);
          toggleBadResponseNotification(true);
          setPhysicalRoomWHConflictList([]);
        }
      } else {
        setBadResponseMsg(localization.someThingWentWrong);
        toggleBadResponseNotification(true);
        setPhysicalRoomWHConflictList([]);
      }
    }
  }, [saveWorkingHoursLoadState]);

  const isLoadingState =
    saveWorkingHoursLoadState === status.SUBMITING ||
    getWorkingHoursLoadState === status.FETCHING ||
    isLoading;

  const imageBase64 = `${process.env.REACT_APP_CDN_URL}/assets/user-placeholder-blue.png`;
  return (
    <div>
      {doctorInfo.AccountKey ? (
        <div>
          {isLoadingState ? (
            <div style={{ display: 'flex', padding: '100px 0px', justifyContent: 'center' }}>
              <Spinner radius={90} />
            </div>
          ) : (
            <div style={{ paddingBottom: '45px' }}>
              <DoctorInfoContainer>
                <DoctorInfoWrapper>
                  <div style={{ display: 'flex' }}>
                    {doctorInfo.doctorPhoto ? (
                      <DoctorImageContainer>
                        <DoctorImage src={doctorInfo.doctorPhoto} alt="doctorPhoto" />
                      </DoctorImageContainer>
                    ) : (
                      <DoctorImageContainer>
                        <DoctorImage src={imageBase64} alt="doctorPhoto" />
                      </DoctorImageContainer>
                    )}
                    <div style={{ margin: '0px 8px' }}>
                      <DoctorName>{doctorInfo.doctorName}</DoctorName>
                      <DoctorSpecialty>{doctorInfo.specialtyName}</DoctorSpecialty>
                    </div>
                  </div>
                  <BookingTypeOptionsContainer>
                    <div style={{ width: '146px' }}>
                      {canReadOnlyWH ? (
                        <Field
                          componentName="workingHoursTypes-list"
                          fieldValue={getStaticFieldValue(
                            doctorWorkingHoursInfo.workingHoursType,
                            workingHoursTypes,
                          )}
                          extendFieldContainer={ExtendDropDownStyle}
                        />
                      ) : (
                        <Field
                          extendDropDownListItem={ExtendDropDownListItem}
                          extendDropDownStyle={ExtendDropDownStyle}
                          fieldValue={doctorWorkingHoursInfo.workingHoursType}
                          componentName="workingHoursTypes-list"
                          options={workingHoursTypes}
                          onChanges={val => onChangeWorkingHoursType(val)}
                          icon="downcarret"
                          iconSize={11}
                          isDynamic
                          isDropDown
                          isRequired
                          language={language}
                          isValid
                        />
                      )}
                    </div>
                    <div style={{ width: '345px' }}>
                      {canReadOnlyWH ? (
                        <Field
                          componentName="reservationWindowOptions-list"
                          fieldValue={getStaticFieldValue(
                            doctorWorkingHoursInfo.reservationWindow,
                            reservationWindowOptions,
                          )}
                          extendFieldContainer={ExtendDropDownStyle}
                        />
                      ) : (
                        <Field
                          extendDropDownListItem={ExtendDropDownListItem}
                          extendDropDownStyle={ExtendDropDownStyle}
                          fieldValue={doctorWorkingHoursInfo.reservationWindow}
                          componentName="reservationWindowOptions-list"
                          options={reservationWindowOptions}
                          onChanges={val => onChangeReservationWindow(val)}
                          icon="downcarret"
                          iconSize={11}
                          isDynamic
                          isDropDown
                          isRequired
                          language={language}
                          isValid
                        />
                      )}
                    </div>
                  </BookingTypeOptionsContainer>
                </DoctorInfoWrapper>
              </DoctorInfoContainer>
              <Container>
                <div style={{ marginBottom: '5px' }}>
                  <NotificationBar
                    isShowed={isBadResponse}
                    iconName="exclamation"
                    iconColor="#fff"
                    iconSize={20}
                    notificationMessage={badResponseMsg}
                    extendBarContainer={ExtendBarContainer}
                  />
                </div>
                <div>
                  {doctorWorkingHoursInfo.workingHours.map((dayObj, index) => (
                    <div>
                      <DayWorkingHours
                        localization={localization}
                        {...dayObj}
                        index={index}
                        language={language}
                        setOverLap={setOverLap}
                        addNewShift={addNewShift}
                        removeShift={removeShift}
                        onChangeSelection={onChangeSelection}
                        onChangeTime={onChangeTime}
                        onChangeDuration={onChangeDuration}
                        dayNameLocalized={localization[String(dayObj.DayOfWeek).toLowerCase()]}
                        isFifo={doctorWorkingHoursInfo.workingHoursType === APPOITMENTS_TYPES.FIFO}
                        getRoomsResponse={getRoomsResponse}
                        branchWorkingHours={branchWorkingHours}
                        dayWorkingHoursDetails={branchWorkingHoursDetails[index]}
                        physicalRoomWHConflictList={physicalRoomWHConflictList}
                        canReadOnlyWH={canReadOnlyWH}
                      />
                      {index < 6 && (
                        <SeparatorContainer>
                          <SeparatorLine />
                        </SeparatorContainer>
                      )}
                    </div>
                  ))}
                </div>
              </Container>
              {!canReadOnlyWH && (
                <ButtonContainer>
                  <NewButton
                    btnText={localization.save}
                    disabled={!isValid(true)}
                    onClick={() => onSave()}
                    extendButtonStyle={savebtnExtendedStyle}
                  />
                </ButtonContainer>
              )}
            </div>
          )}
        </div>
      ) : (
        <div style={{ padding: '100px 0px' }}>
          <EmptyState
            image={`${process.env.REACT_APP_CDN_URL}/assets/filter.svg`}
            text={localization.emptyStates.selectAdoctor}
            imageWidth={225}
          />
        </div>
      )}
      <SnackBarNew snackBarSettings={snackbarSettings} onHide={() => props.hideSnackBar()} />
    </div>
  );
};

const mapStateToProps = state => ({
  clinicsLoaded: state.clinics.loaded,
  clinics: state.clinics,
  roomScheduleTypes: state.room.roomScheduleTypes,
  roomReservationWindow: state.room.roomReservationWindow,
  branches: state.clinics.branches,
  ramadanSchedule: state.schedules.ramadanSchedule,
  workingHours: state.workingHours.WeekDetails,
  workingHoursLoadState: state.workingHours.loadState,
  saveLoadState: state.workingHours.saveState,
  getRoomsResponse: state.room.getRoomsResponse,
  getBranchWorkingHoursLoadState: state.workingHours.getBranchWorkingHoursLoadState,
  branchWorkingHours: state.workingHours.branchWorkingHours,
  saveWorkingHoursLoadState: state.workingHours.saveWorkingHoursLoadState,
  saveWorkingHoursResponse: state.workingHours.saveWorkingHoursResponse,
  getWorkingHoursLoadState: state.workingHours.getWorkingHoursLoadState,
  snackbarSettings: state.widgets.snackbarSettings,
});

ManageDoctorWorkingHours.propTypes = {
  onCloseAction: PropTypes.func.isRequired,
  language: PropTypes.string,
  localization: PropTypes.object,
  showModal: PropTypes.bool,
  branchKey: PropTypes.string,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRoomDetails,
      loadSchedules,
      setRoomScheduleTypes,
      setRoomReservationWindow,
      loadWorkingHours,
      saveWorkingHours,
      getRooms,
      getBranchWorkingHours,
      showSnackBar,
      hideSnackBar,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ManageDoctorWorkingHours);
