import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NewFilter from './NewFilter';
import ManageDoctorWorkingHours from './ManageDoctorWorkingHours';

class WorkingHours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRoom: '',
      selectedDoctor: '',
      branchKey: '',
    };
  }

  handleFilterChange = (selectedRoom, selectedDoctor, currentBranchKey) => {
    // Get branchKey
    const { entityDoctors } = this.props;
    let doctorName;
    let specialtyName;
    let doctorPhoto;
    const doctor = entityDoctors.find(doctorObj => doctorObj.AccountKey === selectedDoctor);
    if (doctor) {
      doctorName = doctor.DoctorName;
      doctorName = doctor.DoctorName;
      specialtyName = doctor.MainSpecialty.SpecialityName;
      doctorPhoto = doctor.DrImageUrl;
    }
    if (currentBranchKey !== undefined) {
      this.setState({
        branchKey: currentBranchKey,
        selectedRoom,
        selectedDoctor,
        doctorPhoto,
        doctorName,
        specialtyName,
      });
    }
  };

  render() {
    const {
      selectedRoom,
      selectedDoctor,
      branchKey,
      doctorPhoto,
      doctorName,
      specialtyName,
    } = this.state;
    const { localization, language, mixpanel, isRamadanWH } = this.props;
    return (
      <div>
        <NewFilter
          onFilterChange={this.handleFilterChange}
          mixpanel={mixpanel}
          language={language}
          localization={localization}
          isRamadanWH={isRamadanWH}
        />
        <ManageDoctorWorkingHours
          isRamadanWH={isRamadanWH}
          branchKey={branchKey}
          language={language}
          doctorInfo={{
            AccountKey: selectedDoctor,
            RoomKey: selectedRoom,
            doctorPhoto,
            doctorName,
            specialtyName,
          }}
        />
      </div>
    );
  }
}

WorkingHours.propTypes = {
  entityDoctors: PropTypes.array,
  language: PropTypes.string,
  mixpanel: PropTypes.object,
};

export default WorkingHours;
