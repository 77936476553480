import styled, { css } from 'styled-components';
import { Tooltip } from '@vezeeta/web-components';

export const SlotsDropDownContainer = styled.div`
  width: 105px;
`;

export const WarningMessage = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  color: #ff0c00;
  margin: 0px 8px;
  padding: 0px 24px;
`;

export const ExtendDropDownStyle = css`
  height: 28px;
  background-color: unset;
  font-size: 13px;
  padding: 2px 12px;
`;

export const SlotsDropDownsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0px 24px;
  ${props => props.theme.media.phone`
    flex-direction: column;
    margin-bottom: 20px;
  `};
`;

export const SlotDropDownsContainer = styled.div`
  display: flex;
  ${props => props.theme.media.phone`
  flex-direction: column;
  height: 65px;
  justify-content: space-between;
`};
`;

export const DayName = styled.p`
  color: #484848;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
`;

export const DayWorkingHoursContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DropDownSeparator = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 28px;
  color: #484848;
  margin: 0px 8px;
`;

export const ExtendDropDownListItem = css`
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #484848;
  padding: 6px 0px 6px 0px;
  box-shadow: unset;
`;

export const ExtendDropDownList = css`
  min-width: 97px;
`;

export const AddShift = styled.p`
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #0070cd;
  margin: 0px 6px;
  cursor: pointer;
`;

export const DeleteShiftBtnContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0px 8px;
`;

export const DeleteShiftBtn = styled.div`
  background: #f7f8fa;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const DeleteShiftInnerLine = styled.div`
  display: inline-flex;
  border: 1.5px solid #484848;
  background: #484848;
  width: 50%;
  height: 1px;
  border-width: 1px 1px 1px 1px;
  margin: 0 auto;
`;

export const ExtendedToolTip = styled(Tooltip)`
  .tooltip--message {
    background-color: #1e1e1e !important;
    min-width: unset !important;
    top: unset !important;
    bottom: 110% !important;
    padding: 6px 12px !important;
  }
  .arrow--bottom {
    &::after {
      border-style: solid;
      border-width: 5px;
      content: '';
      position: absolute;

      border-color: #1e1e1e transparent transparent transparent !important;
      left: 50%;
      margin-left: -5px;
      top: 100%;
    }
  }
`;
