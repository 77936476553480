import styled, { css } from 'styled-components';
import { Icon } from '@vezeeta/web-components';

export const CloseIcon = styled(Icon)`
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  justify-content: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  opacity: ${props => (props.isModalShowed ? '1' : '0')};
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: ${props => (props.isModalShowed ? '0' : '15px')};
  transition-delay: ${props => (props.isModalShowed ? '0s, 75ms, 75ms' : '0s, 0s, 0s')};
  transition-duration: 125ms, 50ms, 50ms;
  transition-property: top, opacity, z-index;
  transition-timing-function: linear;
  z-index: ${props => (props.isModalShowed ? '100' : '-1')};
  padding: 0px 20px;
  ${props => props.theme.media.desktop`
    padding: 0px;
  `};
  ${props => props.theme.media.tablet`
    padding: 0px;
  `};
  ${props => props.theme.media.phone`
    padding: 0px;
  `};
`;

export const InnerPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-radius: 5px;
  position: relative;
  width: 775px;
  height: fit-content;
  top: 70px;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  background-color: #fff;
`;

export const IconContainer = styled.div`
  display: inline-flex;
  padding: 0px 25px;
`;

export const HeaderTitle = styled.p`
  font-size: 22px;
  line-height: 30px;
  font-size: 17px;
  line-height: 19px;
  text-align: left;
  padding: 15px 20px;
  display: inline-flex;
  color: #484848;
`;

export const PopUpHeader = styled.div`
  // margin: 10px 0px 15px;
  color: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: inset 0px -1px 0px #e3e6ea;
  border-radius: 5px 5px 0px 0px;
`;

export const CloseLink = styled.div`
  color: #666666;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 0px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 30px;
  justify-content: space-between;
  position: absolute;
  bottom: 25px;
  justify-content: ${props => (props.alignEnd ? 'flex-end' : 'flex-start')};
`;

export const ContainerHeader = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
`;

export const GrayOverLay = styled.div`
  background-color: rgb(185 185 185 / 62%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

// /

export const UserName = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #484848;
  margin-bottom: 10px;
`;
export const ItemContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
export const LabelName = styled.p`
  font-size: 14px;
  color: #9c9c9c;
  line-height: 28px;
  width: 110px;
`;
export const ItemName = styled.p`
  font-size: 14px;
  line-height: 28px;
  color: #484848;
  width: 350px;
  ${props =>
    props.isRTL &&
    `
    direction: ltr;
    text-align: right;
  `};
`;

export const ActiveStatus = styled.div`
  padding: 1px 8px;
  width: fit-content;
  background: #eaf6eb;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #31a636;
`;

export const InActiveStatus = styled.div`
  padding: 1px 8px;
  width: fit-content;
  background: #e3e6ea;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #484848;
`;

export const Container = styled.div`
  width: 100%;
  padding: 0px 16px;
`;

export const EditIconConatiner = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  margin-bottom: 10px;
`;

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px;
`;

export const SeparatorLine = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #e3e6ea;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 10px 19px;
  display: flex;
  justify-content: flex-end;
  box-shadow: inset 0px 1px 0px #f1f4f6;
`;

export const cancelbtnExtendedStyle = css`
  width: 100px;
  color: #484848;
  background: #ffffff;
  border: 1px solid #e3e6ea;
  border-radius: 8px;
  margin: 0px 12px;
`;

export const savebtnExtendedStyle = css`
  ${cancelbtnExtendedStyle};
  color: #ffffff;
  background: #0070cd;
  margin: 0px 0px;
  width: 145px;
`;

export const ExtendBarContainer = css`
  height: fit-content;
  padding: 5px 17px;
`;

export const DoctorInfoContainer = styled.div`
  width: 100%;
  height: 72px;
  padding: 18px 16px;
  border-bottom: 1px solid #e3e6ea;
  ${props => props.theme.media.phone`
    height: 135px;
  `};
`;

export const BookingTypeOptionsContainer = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  ${props => props.theme.media.phone`
    flex-direction: column;
    width: 100%;
    height: 65px;
    margin: 8px 0px;
  `};
`;

export const DoctorInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${props => props.theme.media.phone`
    flex-direction: column;
  `};
`;

export const DoctorImage = styled.img`
  border-radius: 50px;
  height: 28px;
  width: 28px;
  border: 2px solid #ffffff;
`;

export const DoctorName = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #484848;
`;

export const DoctorSpecialty = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #7e7e7e;
`;

export const DoctorImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StatusLabel = styled.div`
  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  margin: 3px 3px;
  justify-content: space-between;
  justify-content: center;
  width: fit-content;
`;

export const StatusLabelBlue = styled(StatusLabel)`
  background-color: #e5e5e5;
`;
export const StatusLabelRed = styled(StatusLabel)`
  background-color: #ffeae9;
`;
export const StatusLabelYellow = styled(StatusLabel)`
  background-color: #fff0d3;
`;

export const StatusBadgeMsg = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #484848;
`;
