/* eslint-disable prefer-const */
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Filter } from '@vezeeta/web-components';
import _ from 'lodash';
import FilterUtils from '@vezeeta/enterprise-utils/lib/Filter';
import ClinicsUtils from '@vezeeta/enterprise-utils/lib/Clinics';
import { FILTER_NAMES, doctorsStatusList } from '@vezeeta/enterprise-utils/lib/Constants';
import LoadingFilter from './LoadingFilter';


/**
 * Get initial filter state with first branch selected
 * @param {array} clinics
 * @returns {object} initial filter state representation
 */
const getInitialFilterDataOnClinicsLoad = clinics => {
  const clinicsData = ClinicsUtils.flattenClinics(clinics);
  const firstBranchKey = clinicsData.branches[0].BranchKey;
  const filterData = {
    [FILTER_NAMES.BRANCHES]: firstBranchKey,
  };
  const filteredClinics = FilterUtils.filterClinics(clinics, filterData);

  return {
    selectedBranch: { value: firstBranchKey, selectFirst: true },
    // selectedDoctorsStatus: { value: 0, selectFirst: true },
    filteredClinics,
  };
};

class FilterContainer extends Component {
  constructor(props) {
    super(props);

    // Check if clinics already loaded and get initial state in that case
    const filterData = props.clinicsLoaded ? getInitialFilterDataOnClinicsLoad(props.clinics) : {};

    this.state = {
      selectedBranch: { value: null, selectFirst: false },
      selectedDoctorsStatus: { value: 5, selectFirst: false },
      selectedDoctors: { isAllSelected: false },
      selectedDoctorsInfo: [],
      filteredClinics: [],
      ...filterData,
      hasDoctors: false,
      clinics: props.clinics,
      isFilterChanged: false,
    };
  }

  /**
   * If the clinics are loading for the first time set initial filter state
   * when loading is complete
   */
  static getDerivedStateFromProps(props, prevState) {
    const { clinicsLoaded, clinics } = props;
    const {
      selectedBranch: { value },
    } = prevState;

    if (!value && clinicsLoaded && !_.isEqual(prevState.clinics, clinics)) {
      const filterData = getInitialFilterDataOnClinicsLoad(clinics);
      return { ...filterData };
    }
    return null;
  }

  /**
   * Call onChange callback on every selectedDoctorsInfo update
   */
  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    const { selectedDoctorsInfo, hasDoctors, selectedBranch } = this.state;

    if (prevState.selectedDoctorsInfo !== selectedDoctorsInfo && onChange) {
      onChange(selectedDoctorsInfo, hasDoctors, selectedBranch);
    }
  }

  /**
   * Filter component onChange handler
   * @param {object} filterData
   */
  handleFilterChange = filterData => {
    const { mixpanel, location } = this.props;
    let filteredData = null;
    if (!filterData.DOCTORS) {
      filteredData = { ...filterData, DOCTORS: [] };
    } else {
      filteredData = filterData;
    }
    const selectedBranch = this.state.selectedBranch.value;
    const selectedDoctorsStatus = this.state.selectedDoctorsStatus.value;

    let {
      [FILTER_NAMES.BRANCHES]: nextSelectedBranch,
      [FILTER_NAMES.ROOMS]: nextSelectedRoomsIds,
      [FILTER_NAMES.DOCTORS]: nextSelectedDoctorsIds,
      DOCTORS_STATUS: nextSelectedDoctorsStatus,
    } = filteredData;
    nextSelectedBranch = nextSelectedBranch || selectedBranch;
    nextSelectedDoctorsStatus = nextSelectedDoctorsStatus || selectedDoctorsStatus;

    const selectedBranchChanged = nextSelectedBranch !== selectedBranch;
    /*
    NOTE: This is commented is case we needed to re-enable it again
    const selectedRoomsIds = this.state.selectedRooms.selectedIds;
    const selectedRoomsChanged =
    selectedRoomsIds &&
    nextSelectedRoomsIds &&
    !nextSelectedRoomsIds.every(id => selectedRoomsIds.includes(id));
    */

    // Reset selected doctors if selected branch or room changed

    /*     const nextSelectedDoctors = selectedBranchChanged
      ? { isAllSelected: true, resetSection: true }
      : { selectedIds: nextSelectedDoctorsIds };
 */
    const nextSelectedDoctors = { isAllSelected: false, resetSection: true };

    // Exclude rooms data if selected branch changed and exclude doctors data
    // if selected branch or doctors changed
    const adaptedFilterData = {
      ...filteredData,
      [FILTER_NAMES.BRANCHES]: nextSelectedBranch,
      [FILTER_NAMES.ROOMS]: selectedBranchChanged ? undefined : nextSelectedRoomsIds,
      [FILTER_NAMES.DOCTORS]: selectedBranchChanged ? [] : nextSelectedDoctorsIds,
    };

    const filteredClinics = FilterUtils.filterClinics(this.props.clinics, adaptedFilterData);
    const filteredClinicsData = ClinicsUtils.flattenClinics(filteredClinics);
    const selectedDoctorsInfo = ClinicsUtils.getDoctorsInfo(filteredClinicsData.rooms);

    const clinicsData = ClinicsUtils.flattenClinics(this.props.clinics);
    const availableDoctors = ClinicsUtils.getDoctorsInRooms(
      clinicsData.rooms,
      filteredClinicsData.rooms.map(room => room.RoomKey),
      6,
    );

    let hasDoctors = true;
    if (filteredClinicsData.rooms.length > 0) {
      hasDoctors = availableDoctors.length > 0;
    }

    const selectedBranchObj =
      clinicsData.branches.length &&
      clinicsData.branches.filter(branch => branch.BranchKey === nextSelectedBranch);
    if (
      clinicsData.doctors.length &&
      selectedDoctorsInfo.length !== this.state.selectedDoctorsInfo.length
    ) {
      mixpanel.track('Click Filter By Doctor', {
        'Page Name': location.pathname.replace('/', ''),
        'Is All Doctors': availableDoctors.length === selectedDoctorsInfo.length,
        'Selected Branch': selectedBranchObj[0].BranchName,
      });
    }

    if (selectedBranch !== nextSelectedBranch && selectedBranchObj[0]) {
      const previousSelectedBranchObj =
        clinicsData.branches.length &&
        clinicsData.branches.filter(branch => branch.BranchKey === selectedBranch);
      mixpanel.track('Click Filter By Branch', {
        'Page Name': location.pathname.replace('/', ''),
        'Selected Branch': selectedBranchObj[0].BranchName,
        'Old Branch': previousSelectedBranchObj[0].BranchName,
      });
    }

    this.setState({
      selectedDoctorsStatus: { value: nextSelectedDoctorsStatus },
      selectedBranch: { value: nextSelectedBranch },
      selectedDoctors: nextSelectedDoctors,
      filteredClinics,
      selectedDoctorsInfo,
      hasDoctors,
      isFilterChanged: selectedBranchChanged,
    });
  };

  render() {
    const { enableDoctorsStatusFilterList } = window;
    const { clinics, clinicsLoaded, language, localization, hideHomeVisitsBranches } = this.props;
    const {
      filteredClinics,
      selectedBranch,
      selectedDoctors,
      isFilterChanged,
      selectedDoctorsStatus,
    } = this.state;
    const clinicsData = ClinicsUtils.flattenClinics(clinics);
    const filteredClinicsData = ClinicsUtils.flattenClinics(filteredClinics);

    // Branches Section
    let branchesSection;
    if (hideHomeVisitsBranches) {
      branchesSection = FilterUtils.createBranchesSection(
        clinicsData.branches.filter(b => b.BookingTypeId !== 3),
        {
          selectFirst: selectedBranch.selectFirst,
          renderClinicName: clinicsData.hasMultipleClinics,
        },
        localization && localization.branches,
      );
    } else {
      branchesSection = FilterUtils.createBranchesSection(
        clinicsData.branches,
        {
          selectFirst: selectedBranch.selectFirst,
          renderClinicName: clinicsData.hasMultipleClinics,
        },
        localization && localization.branches,
      );
    }
    // Statuses Section
    let doctorsStatusSection;
    if (enableDoctorsStatusFilterList) {
      doctorsStatusSection = FilterUtils.createDoctorsStatusSection(
        doctorsStatusList,
        language,
        {
          selectFirst: selectedDoctorsStatus.selectFirst,
          renderClinicName: false,
        },
        language === 'en' ? 'Doctors Status' : 'حالات الأطباء',
        selectedDoctorsStatus.value,
      );
    }
    // Doctors Section
    const doctorsSection = FilterUtils.createDoctorsSection(
      ClinicsUtils.getDoctorsInRooms(
        clinicsData.rooms,
        filteredClinicsData.rooms.map(room => room.RoomKey),
        enableDoctorsStatusFilterList ? selectedDoctorsStatus.value : 6,
      ),
      selectedDoctors,
      localization,
    );

    const filterSections = enableDoctorsStatusFilterList
      ? [branchesSection, doctorsStatusSection, doctorsSection]
      : [branchesSection, doctorsSection];

    let marginProps;
    if (language === 'en') {
      marginProps = {
        mr: 2,
      };
    } else {
      marginProps = {
        ml: 2,
      };
    }

    return (
      <div>
        {clinicsLoaded ? (
          <Fragment>
            <div>
              <Filter
                title={localization.filterBy}
                sections={filterSections}
                onChange={this.handleFilterChange}
                language={language}
                otherProps={marginProps}
                isFilterChanged={isFilterChanged}
              />
            </div>
          </Fragment>
        ) : (
          <LoadingFilter />
        )}
      </div>
    );
  }
}

FilterContainer.propTypes = {
  clinics: PropTypes.array,
  clinicsLoaded: PropTypes.bool,
  onChange: PropTypes.func,
  localization: PropTypes.object,
  language: PropTypes.string,
  hideHomeVisitsBranches: PropTypes.bool,
  location: PropTypes.object,
  mixpanel: PropTypes.object,
};

const mapStateToProps = state => ({
  clinics: state.clinics.Clinics,
  clinicsLoaded: state.clinics.loaded,
});

export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(FilterContainer),
);
