import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { loadSchedules, SCHEDULES } from '@vezeeta/enterprise-store/lib/actions/schedules';
import { isToday } from '@vezeeta/enterprise-utils/lib/Helpers';

import { Icon, Subheading, Text, IconsStore } from '@vezeeta/web-components';
import Icons from '@vezeeta/web-icons';
import './ScheduleNotifier.css';

class ScheduleNotifier extends Component {
  componentDidMount() {
    const { state, isUserLoaded } = this.props;
    if (state !== SCHEDULES.SUCCEEDED && isUserLoaded) {
      this.props.loadSchedules();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isUserLoaded !== this.props.isUserLoaded &&
      this.props.isUserLoaded &&
      this.props.state !== SCHEDULES.SUCCEEDED
    ) {
      this.props.loadSchedules();
    }
  }

  render() {
    const { state, history, language, icon } = this.props;
    let { startDisplayDate, endDate, startDate } = this.props;

    startDisplayDate = new Date(startDisplayDate);
    const currentDate = new Date();
    endDate = new Date(endDate);
    const shouldRender =
      (isToday(startDisplayDate, currentDate) || currentDate > startDisplayDate) &&
      currentDate < endDate;

    if (state === SCHEDULES.LOADING || !shouldRender) {
      return <div />;
    }

    startDate = new Date(startDate);

    const startDateDay =
      startDate && startDate.toLocaleDateString(`${language}-EG`, { weekday: 'short' }); // Returns Wed/Thr
    const startDateDayAndMonth =
      startDate &&
      startDate.toLocaleDateString(`${language}-EG`, {
        month: 'short',
        day: 'numeric',
      }); // Returns Feb, 28
    const endDateDay =
      endDate && endDate.toLocaleDateString(`${language}-EG`, { weekday: 'short' });
    const endDateDayAndMonth =
      endDate &&
      endDate.toLocaleDateString(`${language}-EG`, {
        month: 'short',
        day: 'numeric',
      });

    return (
      <div className="schedule-notifier__container">
        <div className="schedule-notifier__grid">
          <Icon icon={new IconsStore(Icons).getIcon(icon)} color="#fff" width={35} />
          <div>
            <Subheading className="schedule-notifier__title">
              {language === 'en' ? 'Ramadan is here' : 'شهر رمضان'}
            </Subheading>
            <Text className="schedule-notifier__date">{`${startDateDay}, ${startDateDayAndMonth}`}</Text>
            <Text className="schedule-notifier__to"> {language === 'en' ? 'to' : 'إلى '}</Text>
            <Text className="schedule-notifier__date">{`${endDateDay}, ${endDateDayAndMonth}`}</Text>
          </div>
        </div>
        <button
          onClick={() => {
            history.push('/working-hours-ramadan');
          }}
          className="schedule-notifier__btn"
        >
          {language === 'en' ? 'Adjust your schedule' : 'اضبط مواعيد العمل'}
        </button>
      </div>
    );
  }
}

ScheduleNotifier.propTypes = {
  icon: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startDisplayDate: PropTypes.string,
  loadSchedules: PropTypes.func,
  state: PropTypes.oneOf(Object.values(SCHEDULES)),
  history: PropTypes.object,
  isUserLoaded: PropTypes.bool,
  language: PropTypes.string,
};

ScheduleNotifier.defaultProps = {
  icon: 'crescent',
};

const mapStateToProps = state => ({
  startDate: state.schedules.ramadanSchedule.StartDate,
  endDate: state.schedules.ramadanSchedule.EndDate,
  startDisplayDate: state.schedules.ramadanSchedule.StartDisplayDate,
  state: state.schedules.state,
  isUserLoaded: state.user.loaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({ loadSchedules }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScheduleNotifier));
