import React from 'react';
import { ShimmerEffect, Circle, Rect, NoAnimationBox } from '@vezeeta/web-components';

import './LoadingFilter.css';

function LoadingFilter() {
  return (
    <ShimmerEffect flexDirection="column">
      <NoAnimationBox className="filter">
        <NoAnimationBox className="filter-section">
          <Rect width={160} />
        </NoAnimationBox>

        <NoAnimationBox className="filter-section">
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
        </NoAnimationBox>

        <NoAnimationBox className="filter-section">
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
          <NoAnimationBox className="filter-item">
            <Circle radius={20} mr={3} />
            <Rect width={140} />
          </NoAnimationBox>
        </NoAnimationBox>
      </NoAnimationBox>
    </ShimmerEffect>
  );
}

export default LoadingFilter;
