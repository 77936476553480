/* eslint-disable quotes */
/* eslint-disable import/prefer-default-export */
export const translation = {
  en: {
    fillInRequiredFields: 'Fill in field',
    endShiftGreaterError: 'Shift end time has to be greater than shift start time',
    shiftOverlap: 'Overlapping Shifts',
    overlapTooltip: "This shift is overlapping with another doctor's shift.",
    conflictMessage: 'Doctor working hours should be within branch working hours.',
    overlap: 'Overlap',
    manageDoctorWorkingHours: 'Manage doctor working hours ',
    saturday: 'Saturday',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    save: 'Save',
    cancel: 'Back',
    add: 'Add branch working hours',
    edit: 'Edit branch working hours',
    someThingWentWrong: 'Something went wrong, please try again!',
    conflictErrorMsg:
      "Some doctors have shifts outside the branch working hours. Please make sure that all doctors' shifts are within branch working hours.",
    emptyStates: {
      noDoctors: 'No doctors avaliable at the moment',
      addDoctorButton: 'Add Doctors',
      selectAdoctor: 'Please select a doctor first from the filter',
    },
    normalWorkingHours: 'Normal Working Hours',
    ramadanWorkingHours: 'Ramadan Working Hours',
    startingFrom: '',
    noOptions: 'No options are available',
    onAppointment: 'On Appointment',
    fifo: 'Fifo',
    reservationWindowOptions: 'Reservation Window Option',
    shift: 'Shift',
    addShift: 'Add Shift',
    from: 'from',
    to: 'to',
    duration: 'Duration',
    slots: 'Slots',
    doctor: 'Doctor',
    reservationOptions: {
      acceptAllBookings: 'Accept all bookings',
      startingOfWorkingHours: 'Block booking By starting of working hours',
      oneHourBeforeWorkingHours: 'Block booking 1 hour before working hours',
      twoHoursBeforeWorkingHours: 'Block booking 2 hours before working hours',
      fourHoursBeforeWorkingHours: 'Block booking 4 hours before working hours',
      sixHoursBeforeWorkingHours: 'Block booking 6 hours before working hours',
      eightoursBeforeWorkingHours: 'Block booking 8 hours before working hours',
      blockSameDayBookings: 'Block same day bookings',
    },
    startTime: 'Start Time',
    endTime: 'End Time',
    ramadan: 'Ramadan',
    filter: {
      filterBy: 'Filter By',
      doctors: 'Doctors',
      branches: 'Branches',
      noOptions: 'No options are available',
    },
    workingHoursSavedSuccessfully: 'Working hours saved successfully',
    selectOneDay: 'Select at least one working day',
  },
  ar: {
    fillInRequiredFields: 'ادخل البيانات',
    endShiftGreaterError: 'موعد نهاية فترة العمل يجب أن يكون بعد موعد بداية فترة العمل',
    shiftOverlap: 'ميعاد فترة العمل متداخل مع فترة أخرى',
    overlapTooltip: 'مواعيد الدكتور تتعارض مع مواعيد دكتور آخر فى نفس الغرفة',
    conflictMessage: 'مواعيد عمل الدكتوريجب أن تكون فى خلال مواعيد عمل الفرع',
    overlap: 'تعارُض',
    manageDoctorWorkingHours: 'إدارة مواعيد عمل الدكتور',
    saturday: 'السبت',
    sunday: 'الاحد',
    monday: 'الاثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الاربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    save: 'حفظ',
    cancel: 'رجوع',
    edit: 'تعديل مواعيد عمل العيادة',
    add: 'أضف مواعيد عمل العيادة',
    someThingWentWrong: 'حدث خطأ, يرجي اعادة المحاولة',
    conflictErrorMsg:
      ' بعض الأطباء لديهم مواعيد عمل خارج مواعيد عمل الفرع. يرجى التأكد من أن مواعيد جميع الأطباء داخل مواعيد عمل الفرع.',
    emptyStates: {
      noDoctors: 'لا يوجد أطباء',
      addDoctorButton: 'إضافة طبيب',
      selectAdoctor: 'برجاء اختيار طبيب من القائمة الجانبية',
    },
    normalWorkingHours: 'مواعيد العمل العادية',
    ramadanWorkingHours: 'مواعيد عمل شهر رمضان',
    startingFrom: 'إبتداء من',
    noOptions: 'لا توجد خيارات متاحة',
    onAppointment: 'مواعيد محددة',
    fifo: 'أسبقية الحضور',
    reservationWindowOptions: 'منع حجوزات من فيزيتا',
    shift: 'فترة عمل',
    addShift: 'إضافة فترة عمل',
    from: 'من',
    to: 'إلى',
    duration: 'المدة',
    slots: 'عدد الكشوفات',
    doctor: 'الطبيب',
    reservationOptions: {
      acceptAllBookings: 'قبول جميع الحجوزات',
      startingOfWorkingHours: 'منع الحجز مع بداية عمل العيادة',
      oneHourBeforeWorkingHours: 'منع الحجز بساعة من بداية عمل العيادة',
      twoHoursBeforeWorkingHours: 'منع الحجز بساعتين من بداية عمل العيادة',
      fourHoursBeforeWorkingHours: 'منع الحجز ب ٤ ساعات من بداية عمل العيادة',
      sixHoursBeforeWorkingHours: 'منع الحجز ب ٦ ساعات من بداية عمل العيادة',
      eightoursBeforeWorkingHours: 'منع الحجز ب ٨ ساعات من بداية عمل العيادة',
      blockSameDayBookings: 'منع الحجز في نفس اليوم',
    },
    startTime: 'وقت البدء',
    endTime: 'وقت الانتهاء',
    ramadan: 'شهر رمضان',
    filter: {
      filterBy: 'تصفية',
      doctors: 'الأطباء',
      branches: 'الفروع',
      noOptions: 'لا توجد خيارات متاحة',
    },
    workingHoursSavedSuccessfully: 'تم حفظ مواعيد العمل بنجاح',
    selectOneDay: 'اختر يوم عمل واحد على الأقل',
  },
};
