import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBranchWorkingHours } from '@vezeeta/enterprise-store/lib/actions/workingHours';
import { getRooms } from '@vezeeta/enterprise-store/lib/actions/room';
import Filters from './Filters';
import { getBranchesForDropDown, getDoctorsDropDownList } from './Filters.helper';

const FiltersContainer = ({ ...props }) => {
  const { branches, onFilterChange, language, isRamadanWH } = props;
  const [branchesList, setBranchesList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState();
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctor, setSelectedDoctorsList] = useState();

  // Branches List
  const onChangeBranch = branchKey => {
    setSelectedBranch(branchKey);
    setSelectedDoctorsList(undefined);
    setDoctorsList([]);
    const currentBranch = branches.filter(branch => branch.BranchKey === branchKey)[0];
    const currentDoctorsList = [];
    currentBranch.Rooms.forEach(room => {
      room.Doctors.forEach(doctor => {
        currentDoctorsList.push({
          DoctorName: doctor.DoctorName,
          AccountKey: doctor.AccountKey,
          RoomKey: room.RoomKey,
        });
      });
    });
    onFilterChange(undefined, undefined, branchKey);
    setDoctorsList(getDoctorsDropDownList(currentDoctorsList));
  };

  useEffect(() => {
    if (branches.length && !branchesList.length) {
      setBranchesList(getBranchesForDropDown(branches));
      onChangeBranch(branches[0].BranchKey);
    }
  }, [branches]);

  // Doctors
  const onChangeDoctors = key => {
    const currentBranch = branches.filter(branch => branch.BranchKey === selectedBranch)[0];
    let selectedRoomKey;
    currentBranch.Rooms.forEach(room => {
      room.Doctors.forEach(doctor => {
        if (key === doctor.AccountKey) {
          selectedRoomKey = room.RoomKey;
        }
      });
    });
    onFilterChange(selectedRoomKey, key, selectedBranch);
    setSelectedDoctorsList(key);
  };

  return (
    <Filters
      {...props}
      branchesList={branchesList}
      selectedBranch={selectedBranch}
      doctorsList={doctorsList}
      selectedDoctor={selectedDoctor}
      onChangeBranch={onChangeBranch}
      onChangeDoctors={onChangeDoctors}
      language={language}
      isRamadanWH={isRamadanWH}
    />
  );
};

FiltersContainer.propTypes = {
  selectedDate: PropTypes.string,
  onChangeDate: PropTypes.func,
  enableAddAppointment: PropTypes.bool,
  language: PropTypes.string,
  localization: PropTypes.object,
  appointmentsAutoUpdate: PropTypes.func,
  onClickAddAppointment: PropTypes.func,
  selectedView: PropTypes.string,
  onChangeViewSelection: PropTypes.func,
  mixpanel: PropTypes.object,
  onSearchChange: PropTypes.func,
  query: PropTypes.string,
  onPatientSelect: PropTypes.func,
  searchedPatients: PropTypes.array,
  isSearchingPatients: PropTypes.bool,
  isTeleHealth: PropTypes.bool,
  isPrimaryCare: PropTypes.bool,
};

const mapStateToProps = state => ({
  clinics: state.clinics.Clinics,
  branches: state.clinics.branches,
  doctors: state.clinics.doctors,
  getRoomsResponse: state.room.getRoomsResponse,
  ramadanSchedule: state.schedules.ramadanSchedule,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getRooms, getBranchWorkingHours }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FiltersContainer);
