/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, Checkbox, Icon, IconsStore } from '@vezeeta/web-components';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';
import Icons from '@vezeeta/web-icons';
import {
  BADGES_TYPES,
  getBidStatusLabel,
  getTimeSlots,
  checkValidInterval,
  getStaticFieldValue,
  getAssignedRoomName,
  isConflictedWHInRoom,
  getDurationSlotsList,
} from '../ManageDoctorWorkingHours.helper';

import {
  ExtendDropDownStyle,
  SlotsDropDownContainer,
  SlotsDropDownsContainer,
  DayName,
  DayWorkingHoursContainer,
  DropDownSeparator,
  ExtendDropDownListItem,
  AddShift,
  DeleteShiftBtn,
  DeleteShiftBtnContainer,
  DeleteShiftInnerLine,
  ExtendedToolTip,
  WarningMessage,
  ExtendDropDownList,
  SlotDropDownsContainer,
} from './DayShifts.style';
import './CheckBoxStyle.css';

const DayWorkingHours = ({ ...props }) => {
  const {
    language,
    index,
    onChangeSelection,
    onChangeTime,
    onChangeDuration,
    dayNameLocalized,
    DayOfWeek,
    DayShifts,
    IsOpened,
    removeShift,
    addNewShift,
    DayOfWeekInt,
    isFifo,
    localization,
    getRoomsResponse,
    physicalRoomWHConflictList,
    isOverlap,
    dayWorkingHoursDetails,
    canReadOnlyWH,
  } = props;

  const { isOpened, branchStartWorkingHour, branchEndWorkingHour } = dayWorkingHoursDetails;

  const renderDayShifts = shiftsList => {
    const list = [];
    if (shiftsList && shiftsList.length) {
      shiftsList.forEach((shift, shiftIndex) => {
        const {
          WorkingHourKey,
          StartTime,
          EndTime,
          SlotDuration,
          SlotsPerShift,
          AssignedPhysicalRoomKey,
        } = shift;
        const timeSlotsList = getTimeSlots(
          language,
          branchStartWorkingHour,
          branchEndWorkingHour,
          isFifo,
          SlotDuration,
        );
        const durationSlotsList = getDurationSlotsList(language, StartTime, EndTime, isFifo);
        const hasConflict = isConflictedWHInRoom(WorkingHourKey, physicalRoomWHConflictList);
        const assignedRoomName = getAssignedRoomName(AssignedPhysicalRoomKey, getRoomsResponse);

        list.push(
          <SlotsDropDownsContainer key={`${DayOfWeek}-${DayOfWeekInt}-${shiftIndex}-workingHours`}>
            <div style={{ display: 'flex' }}>
              <DropDownSeparator>{`${localization.shift} ${
                language === 'en' ? shiftIndex + 1 : toIndiaDigits(String(shiftIndex + 1))
              }`}</DropDownSeparator>
              {hasConflict && (
                <ExtendedToolTip
                  message={localization.overlapTooltip}
                  showOnHover
                  position="bottom"
                  arrowPosition="bottom"
                >
                  <div>{getBidStatusLabel(BADGES_TYPES.RED, localization.overlap)}</div>
                </ExtendedToolTip>
              )}
              {assignedRoomName && (
                <div>{getBidStatusLabel(BADGES_TYPES.YELLOW, assignedRoomName)}</div>
              )}
            </div>
            <div>
              <SlotDropDownsContainer>
                <div style={{ display: 'flex' }}>
                  <DropDownSeparator>{localization.from}</DropDownSeparator>
                  <SlotsDropDownContainer>
                    {canReadOnlyWH ? (
                      <Field
                        componentName={`${DayOfWeek}-from-manageWorkingHours-${shiftIndex}`}
                        fieldValue={getStaticFieldValue(StartTime, timeSlotsList)}
                        extendFieldContainer={ExtendDropDownStyle}
                      />
                    ) : (
                      <Field
                        extendDropDownList={ExtendDropDownList}
                        extendDropDownListItem={ExtendDropDownListItem}
                        extendDropDownStyle={ExtendDropDownStyle}
                        fieldValue={StartTime}
                        componentName={`${DayOfWeek}-from-manageWorkingHours-${shiftIndex}`}
                        options={timeSlotsList}
                        onChanges={val => onChangeTime(index, shiftIndex, 'StartTime', val)}
                        icon="downcarret"
                        iconSize={11}
                        isDynamic
                        isDropDown
                        isRequired
                        isDisabled={!IsOpened}
                        language={language}
                        isValid
                        searchable
                      />
                    )}
                  </SlotsDropDownContainer>
                  <DropDownSeparator>{localization.to}</DropDownSeparator>
                  <SlotsDropDownContainer>
                    {canReadOnlyWH ? (
                      <Field
                        componentName={`${DayOfWeek}-end-manageWorkingHours-${shiftIndex}`}
                        fieldValue={getStaticFieldValue(EndTime, timeSlotsList)}
                        extendFieldContainer={ExtendDropDownStyle}
                      />
                    ) : (
                      <Field
                        extendDropDownList={ExtendDropDownList}
                        extendDropDownListItem={ExtendDropDownListItem}
                        extendDropDownStyle={ExtendDropDownStyle}
                        fieldValue={EndTime}
                        componentName={`${DayOfWeek}-end-manageWorkingHours-${shiftIndex}`}
                        options={timeSlotsList}
                        onChanges={val => onChangeTime(index, shiftIndex, 'EndTime', val)}
                        icon="downcarret"
                        iconSize={11}
                        isDynamic
                        isDropDown
                        isRequired
                        isDisabled={!IsOpened}
                        language={language}
                        isValid
                        searchable
                      />
                    )}
                  </SlotsDropDownContainer>
                </div>
                <div style={{ display: 'flex' }}>
                  <DropDownSeparator>
                    {isFifo ? localization.slots : localization.duration}
                  </DropDownSeparator>
                  <SlotsDropDownContainer>
                    {canReadOnlyWH ? (
                      <Field
                        componentName={`${DayOfWeek}-end-manageWorkingHours-${shiftIndex}`}
                        fieldValue={getStaticFieldValue(
                          isFifo ? SlotsPerShift : SlotDuration,
                          durationSlotsList,
                        )}
                        extendFieldContainer={ExtendDropDownStyle}
                      />
                    ) : (
                      <Field
                        extendDropDownList={ExtendDropDownList}
                        extendDropDownListItem={ExtendDropDownListItem}
                        extendDropDownStyle={ExtendDropDownStyle}
                        fieldValue={isFifo ? SlotsPerShift : SlotDuration}
                        componentName={`${DayOfWeek}-duration-manageWorkingHours-${shiftIndex}`}
                        options={durationSlotsList}
                        onChanges={val => onChangeDuration(index, shiftIndex, val)}
                        icon="downcarret"
                        iconSize={11}
                        isDynamic
                        isDropDown
                        isRequired
                        isDisabled={!IsOpened || !durationSlotsList.length}
                        language={language}
                        isValid
                      />
                    )}
                  </SlotsDropDownContainer>
                  {!canReadOnlyWH && (
                    <DeleteShiftBtnContainer>
                      <DeleteShiftBtn onClick={() => removeShift(index, shiftIndex)}>
                        <DeleteShiftInnerLine />
                      </DeleteShiftBtn>
                    </DeleteShiftBtnContainer>
                  )}
                </div>
              </SlotDropDownsContainer>
              {!checkValidInterval(StartTime, EndTime) && (
                <WarningMessage style={{ padding: '0px' }}>
                  {localization.endShiftGreaterError}
                </WarningMessage>
              )}
              {(StartTime === undefined ||
                EndTime === undefined ||
                SlotsPerShift === undefined ||
                SlotDuration === undefined) && (
                <WarningMessage style={{ padding: '0px' }}>
                  {localization.fillInRequiredFields}
                </WarningMessage>
              )}
            </div>
          </SlotsDropDownsContainer>,
        );
      });
    }

    return list;
  };

  const dayShiftsList = renderDayShifts(DayShifts);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', padding: '6px 0px' }}
      key={`${DayOfWeek}-${DayOfWeekInt}-workingHours`}
    >
      {canReadOnlyWH ? (
        <DayWorkingHoursContainer>
          <div style={{ display: 'flex' }}>
            <DayName style={{ fontWeight: 600 }}>{dayNameLocalized}</DayName>
          </div>
        </DayWorkingHoursContainer>
      ) : (
        <Checkbox
          isChecked={IsOpened}
          onChange={() => onChangeSelection(index)}
          isDisabled={!isOpened}
        >
          <DayWorkingHoursContainer>
            <div style={{ display: 'flex' }}>
              <DayName>{dayNameLocalized}</DayName>
            </div>
          </DayWorkingHoursContainer>
        </Checkbox>
      )}
      {IsOpened && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {dayShiftsList}
          {isOverlap && <WarningMessage>{localization.shiftOverlap}</WarningMessage>}
          {isOpened && !canReadOnlyWH && (
            <div style={{ display: 'flex', padding: '0px 24px' }}>
              <Icon icon={new IconsStore(Icons).getIcon('plus')} width={12} color="#0070CD" />
              <AddShift onClick={() => addNewShift(index)}>{localization.addShift}</AddShift>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DayWorkingHours.propTypes = {
  DayOfWeek: PropTypes.string,
  EndTime: PropTypes.string,
  dayNameLocalized: PropTypes.string,
  language: PropTypes.string,
  localization: PropTypes.object,
  showModal: PropTypes.bool,
  dayName: PropTypes.string,
  IsOpened: PropTypes.bool,
  index: PropTypes.number,
  onChangeSelection: PropTypes.func,
  onChangeTime: PropTypes.func,
  to: PropTypes.string,
  StartTime: PropTypes.string,
  timeSlotsList: PropTypes.array,
  hasError: PropTypes.bool,
};

export default DayWorkingHours;
