/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Icon, IconsStore } from '@vezeeta/web-components';
import Icons from '@vezeeta/web-icons';
import {
  FilterSectionContainer,
  FiltersContainerWrapper,
  FiltersContainer,
  ExtendDropDownListItem,
  ExtendDropDownStyle,
  ExtendFieldText,
  ExtendDropDownDrStyle,
  RamadanContainer,
} from './Filters.styles';

const Filters = ({ ...props }) => {
  const {
    localization,
    branchesList,
    selectedBranch,
    doctorsList,
    selectedDoctor,
    onChangeBranch,
    onChangeDoctors,
    language,
    ramadanSchedule,
    isRamadanWH,
  } = props;

  const ramadanDates = useMemo(() => {
    if (ramadanSchedule && ramadanSchedule.StartDate) {
      const { StartDate, EndDate } = ramadanSchedule;
      return (
        <RamadanContainer>
          <Icon icon={new IconsStore(Icons).getIcon('schedule')} color="#58595b" width={30} />
          <div style={{ margin: '0px 10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ fontWeight: 'bold' }}>{localization.ramadan}</p>
            </div>
            <div style={{ display: 'flex' }}>
              <p>
                {new Date(StartDate).toLocaleDateString(`${language}-EG`, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </p>
              <p style={{ margin: '0px 5px', fontWeight: 'bold' }}>{localization.to}</p>
              <p>
                {new Date(EndDate).toLocaleDateString(`${language}-EG`, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </p>
            </div>
          </div>
        </RamadanContainer>
      );
    }
    return '';
  }, [ramadanSchedule]);

  return (
    <FiltersContainer>
      <FiltersContainerWrapper>
        <FilterSectionContainer>
          <div style={{ minWidth: '90px', margin: '0px 4px' }}>
            <Field
              extendFieldText={ExtendFieldText}
              placeHolder="Branches"
              extendDropDownListItem={ExtendDropDownListItem}
              extendDropDownStyle={ExtendDropDownStyle}
              fieldValue={selectedBranch}
              componentName="filters-branch-dropdown"
              options={branchesList}
              onChanges={val => onChangeBranch(val)}
              icon="downcarret"
              iconSize={12}
              isDynamic
              isDropDown
              language="en"
              fieldIcon="clinic2"
              fieldIconSize={11}
              isDisabled={false}
              variationB
            />
          </div>

          <div style={{ minWidth: '170px', margin: '0px 4px' }}>
            <Field
              extendFieldText={ExtendFieldText}
              placeHolder={localization.doctor}
              extendDropDownListItem={ExtendDropDownListItem}
              extendDropDownStyle={ExtendDropDownDrStyle}
              fieldValue={selectedDoctor}
              componentName="filters-doctors-dropdown"
              options={doctorsList}
              onChanges={val => onChangeDoctors(val)}
              icon="downcarret"
              iconSize={12}
              isDynamic
              isDropDown
              language="en"
              fieldIcon="doctor"
              fieldIconSize={11}
              isDisabled={false}
              variationB
              isCategorized
              searchable
            />
          </div>
        </FilterSectionContainer>
        {isRamadanWH && ramadanDates}
      </FiltersContainerWrapper>
    </FiltersContainer>
  );
};

Filters.propTypes = {
  selectedDate: PropTypes.string,
  onChangeDate: PropTypes.func,
  enableAddAppointment: PropTypes.bool,
  language: PropTypes.string,
  localization: PropTypes.object,
  appointmentsAutoUpdate: PropTypes.func,
  onClickAddAppointment: PropTypes.func,
  selectedView: PropTypes.string,
  onChangeViewSelection: PropTypes.func,
  mixpanel: PropTypes.object,
  onSearchChange: PropTypes.func,
  query: PropTypes.string,
  onPatientSelect: PropTypes.func,
  searchedPatients: PropTypes.array,
  isSearchingPatients: PropTypes.bool,
  isTeleHealth: PropTypes.bool,
  isPrimaryCare: PropTypes.bool,
};

export default Filters;
