import React from 'react';
import { toIndiaDigits } from '@vezeeta/enterprise-utils/lib/Helpers';
import { branchBookingTypes } from '@vezeeta/enterprise-utils/lib/enum';
import WarningLogoRed from 'app/assets/images/Warning-Filled-red.svg';
import WarningLogoYellow from 'app/assets/images/Warning-Filled-yellow.svg';
import WarningLogoBlue from 'app/assets/images/Warning-Filled-blue.svg';
import {
  StatusBadgeMsg,
  StatusLabelBlue,
  StatusLabelRed,
  StatusLabelYellow,
} from './ManageDoctorWorkingHours.styles';

const durationOptions = [5, 10, 15, 20, 30, 40, 45, 60, 90, 105, 120];
export const weekDays = [
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
];

export const APPOITMENTS_TYPES = {
  FIFO: 2,
  ON_APPOINTMENT: 1,
};

export const generateTimeSlots = (language, slotDuration) => {
  const morning = language === 'en' ? 'am' : 'ص';
  const evening = language === 'en' ? 'pm' : 'م';
  const slots = [];
  const durationStep = slotDuration || 5;
  for (let i = 0; i < 24; i += 1) {
    if (i < 12) {
      let hours = '';
      const hours24 = i > 9 ? i : `0${i}`;
      if (i === 0) {
        hours = '12';
      } else {
        hours = i > 9 ? i : `0${i}`;
      }
      for (let j = 0; j < 60; j += durationStep) {
        const minute = j > 9 ? j : `0${j}`;
        const slot1 = {
          timeEn: `${hours24}:${minute}:00`,
          timeLocalized: `${
            language === 'en' ? `${hours}:${minute}` : toIndiaDigits(`${hours}:${minute}`)
          } ${morning}`,
        };
        slots.push(slot1);
      }
    } else {
      let hours = '';
      if (i - 12 === 0) {
        hours = '12';
      } else {
        hours = i - 12 > 9 ? i - 12 : `0${i - 12}`;
      }

      const hours24 = i > 9 ? i : `0${i}`;

      for (let j = 0; j < 60; j += durationStep) {
        const minute = j > 9 ? j : `0${j}`;
        const slot1 = {
          timeEn: `${hours24}:${minute}:00`,
          timeLocalized: `${
            language === 'en' ? `${hours}:${minute}` : toIndiaDigits(`${hours}:${minute}`)
          } ${evening}`,
        };
        slots.push(slot1);
      }
    }
  }
  return slots;
};

export const generateDurationsSlot = language => {
  const minute = language === 'en' ? 'min' : 'د';
  const durationsList = [];
  durationOptions.forEach(i => {
    durationsList.push({
      durationEn: i,
      durationLocalized: `${language === 'en' ? `${i}` : toIndiaDigits(`${i}`)} ${minute}`,
    });
  });
  return durationsList;
};

export const generateFIFOSlot = (language, maxNumberOfSlots, minNumberOfSlots) => {
  const slotsList = [];
  for (let i = 0; i < maxNumberOfSlots - minNumberOfSlots + 1; i += 1) {
    slotsList.push({
      durationEn: i + minNumberOfSlots,
      durationLocalized: `${
        language === 'en' ? `${i + minNumberOfSlots}` : toIndiaDigits(`${i + minNumberOfSlots}`)
      }`,
    });
  }
  return slotsList;
};

export const getFIFOSlot = (language, start, end) => {
  const durationsSlotsList = [];
  if (start && end) {
    const startTime = Number(`${start.split(':')[0]}${start.split(':')[1]}`);
    const endTime = Number(`${end.split(':')[0]}${end.split(':')[1]}`);
    const maxNumberOfSlots = (endTime - startTime) / 5;
    const minNumberOfSlots = Math.ceil((endTime - startTime) / 120);
    const durationSlots = generateFIFOSlot(language, maxNumberOfSlots, minNumberOfSlots);

    durationSlots.forEach(timeObj => {
      durationsSlotsList.push({
        fieldValue: timeObj.durationLocalized,
        value: timeObj.durationEn,
        key: timeObj.durationEn,
        isEnabled: true,
        isChecked: false,
        extras: {},
      });
    });
  }

  return durationsSlotsList;
};

export const getDurationsSlot = language => {
  const durationSlots = generateDurationsSlot(language);
  const durationsSlotsList = [];
  durationSlots.forEach(timeObj => {
    durationsSlotsList.push({
      fieldValue: timeObj.durationLocalized,
      value: timeObj.durationEn,
      key: timeObj.durationEn,
      isEnabled: true,
      isChecked: false,
      extras: {},
    });
  });
  return durationsSlotsList;
};

const checkTimeInInterval = (time, start, end) => {
  let inInterval = false;
  if (time && start && end) {
    const current = Number(`${time.split(':')[0]}${time.split(':')[1]}`);
    const startTime = Number(`${start.split(':')[0]}${start.split(':')[1]}`);
    const endTime = Number(`${end.split(':')[0]}${end.split(':')[1]}`);

    if (current < startTime || current > endTime) {
      inInterval = false;
    } else {
      inInterval = true;
    }
  } else {
    inInterval = true;
  }
  return inInterval;
};

export const checkValidInterval = (start, end) => {
  let isValid = true;
  if (start && end) {
    const startTime = Number(`${start.split(':')[0]}${start.split(':')[1]}`);
    const endTime = Number(`${end.split(':')[0]}${end.split(':')[1]}`);

    if (startTime < endTime) {
      isValid = true;
    } else {
      isValid = false;
    }
  }
  return isValid;
};

export const getDurationSlotsList = (language, startTime, endTime, isFifo) => {
  let list = [];
  if (isFifo) {
    list = getFIFOSlot(language, startTime, endTime);
  } else {
    list = getDurationsSlot(language);
  }
  return list;
};

export const getTimeSlots = (language, startInterval, endInterval, isFiFO, soltDuration) => {
  const timeSlotsList = [];
  if (isFiFO) {
    const timeSlots = generateTimeSlots(language);
    timeSlots.forEach(timeObj => {
      timeSlotsList.push({
        fieldValue: timeObj.timeLocalized,
        value: timeObj.timeEn,
        key: timeObj.timeEn,
        isEnabled: checkTimeInInterval(timeObj.timeEn, startInterval, endInterval),
        isChecked: false,
        extras: {},
      });
    });
  } else {
    const timeSlots = generateTimeSlots(language, soltDuration);

    timeSlots.forEach(timeObj => {
      timeSlotsList.push({
        fieldValue: timeObj.timeLocalized,
        value: timeObj.timeEn,
        key: timeObj.timeEn,
        isEnabled: checkTimeInInterval(timeObj.timeEn, startInterval, endInterval),
        isChecked: false,
        extras: {},
      });
    });
  }

  return timeSlotsList;
};

export const defaultWorkingHours = () => [
  {
    DayOfWeek: 'Saturday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 6,
  },
  {
    DayOfWeek: 'Sunday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 0,
  },
  {
    DayOfWeek: 'Monday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 1,
  },
  {
    DayOfWeek: 'Tuesday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 2,
  },
  {
    DayOfWeek: 'Wednesday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 3,
  },
  {
    DayOfWeek: 'Thursday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 4,
  },
  {
    DayOfWeek: 'Friday',
    DayShifts: [],
    IsOpened: false,
    DayOfWeekInt: 5,
  },
];

export const hideReservationWindowOption = branchBookingTypeId =>
  branchBookingTypeId === branchBookingTypes.PRIMARY_CARE;

// Appointments workinghours dropdown list
export const getWorkingHoursTypes = (branchBookingTypeId, localization) => {
  if (branchBookingTypeId === branchBookingTypes.PHYSCIAL) {
    return [
      {
        key: 'doctorType1',
        value: 1,
        fieldValue: localization.onAppointment,
      },
      {
        key: 'doctorType2',
        value: 2,
        fieldValue: localization.fifo,
      },
    ];
  } else if (
    branchBookingTypeId === branchBookingTypes.HOME_VISIT ||
    branchBookingTypeId === branchBookingTypes.TELEHEALTH
  ) {
    return [
      {
        key: 'doctorType1',
        value: 1,
        fieldValue: localization.onAppointment,
      },
    ];
  } else if (branchBookingTypeId === branchBookingTypes.PRIMARY_CARE) {
    return [
      {
        key: 'doctorType2',
        value: 2,
        fieldValue: localization.fifo,
      },
    ];
  }

  return null;
};

// ReservationWindowOptions dropdown list
export const getReservationWindowOptions = localization => {
  const reservationWindowOptions = [
    {
      value: -1,
      text: localization.reservationOptions.acceptAllBookings,
    },
    {
      value: 0,
      text: localization.reservationOptions.startingOfWorkingHours,
    },
    {
      value: 1,
      text: localization.reservationOptions.oneHourBeforeWorkingHours,
    },
    {
      value: 2,
      text: localization.reservationOptions.twoHoursBeforeWorkingHours,
    },
    {
      value: 4,
      text: localization.reservationOptions.fourHoursBeforeWorkingHours,
    },
    {
      value: 6,
      text: localization.reservationOptions.sixHoursBeforeWorkingHours,
    },
    {
      value: 8,
      text: localization.reservationOptions.eightoursBeforeWorkingHours,
    },
    {
      value: 24,
      text: localization.reservationOptions.blockSameDayBookings,
    },
  ];
  const list = [];
  reservationWindowOptions.forEach(obj => {
    list.push({
      fieldValue: obj.text,
      value: obj.value,
      key: obj.value,
      isEnabled: true,
      isChecked: false,
      extras: {},
    });
  });
  return list;
};

export const BADGES_TYPES = {
  RED: 0,
  YELLOW: 1,
  BLUE: 2,
};

export const getAssignedRoomName = (roomKey, rooms) => {
  let assignedRoomName = '';
  if (rooms && rooms.data.length) {
    const assignedRoom = rooms.data.find(room => room.PhysicalRoomKey === roomKey);
    assignedRoomName = assignedRoom && assignedRoom.Name;
  }
  return assignedRoomName;
};

export const isConflictedWHInRoom = (WorkingHourKey, physicalRoomWHConflictList) => {
  let hasConflict = false;
  if (WorkingHourKey && physicalRoomWHConflictList && physicalRoomWHConflictList.length) {
    hasConflict = physicalRoomWHConflictList.includes(WorkingHourKey);
  }
  return hasConflict;
};

export const getBidStatusLabel = (badgesType, message) => {
  switch (badgesType) {
    case BADGES_TYPES.BLUE:
      return (
        <StatusLabelBlue>
          <img style={{ margin: '0px 2px' }} src={WarningLogoBlue} alt="WarningLogoBlue" />
          <StatusBadgeMsg style={{ margin: '0px 2px' }}>{message}</StatusBadgeMsg>
        </StatusLabelBlue>
      );

    case BADGES_TYPES.RED:
      return (
        <StatusLabelRed>
          <img style={{ margin: '0px 2px' }} src={WarningLogoRed} alt="WarningLogoRed" />
          <StatusBadgeMsg style={{ margin: '0px 2px' }}>{message}</StatusBadgeMsg>
        </StatusLabelRed>
      );

    case BADGES_TYPES.YELLOW:
      return (
        <StatusLabelYellow>
          <img style={{ margin: '0px 2px' }} src={WarningLogoYellow} alt="WarningLogoYellow" />
          <StatusBadgeMsg style={{ margin: '0px 2px' }}>{message}</StatusBadgeMsg>
        </StatusLabelYellow>
      );

    default:
      break;
  }
};

export const getStaticFieldValue = (value, list) => {
  let text = '';
  if (list) {
    const selectedItem = list.find(item => item.value === value);
    if (selectedItem) text = selectedItem.fieldValue;
  }

  return text;
};
